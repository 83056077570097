import gql from 'graphql-tag';

export default gql`
query getAllThemes($groupId: Int!) {
  themes(where: {
    _or: [
      {group_id: {_is_null: true}},
      {group_id: {_eq: $groupId}}
    ]
    }) {
    name
    theme_id
  }
}
`;
