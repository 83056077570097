<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.projectType.name') }}
        </h3>
        <hr>
        <p>
          {{ $t('settings.project.projectType.description') }}
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mb-0 mt-2">
          {{ $t('settings.project.projectType.projectTypeLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-select
          :value="theme.themeId"
          :options="themeList"
          @change="changeThemeId"
        ></b-form-select>
      </b-col>
    </b-row>
    <theme-settings-wrapper
      class="mb-4 pb-4"
      v-if="theme.themeId"
      :theme="theme"
      @update-theme-settings="updateThemeSettings"
    />
  </div>
</template>

<script>
import GetAllThemes from '@/graphQlQueries/queries/getAllThemes';

export default {
  name: 'ProjectSettingsProjectType',
  components: {
    ThemeSettingsWrapper: () => import('@/components/settings/projects/themes/ThemeSettingsWrapper.vue'),
  },
  props: {
    themeId: {
      type: Number,
      default: null,
    },
    groupId: {
      type: Number,
      default: null,
    },
    themeSettings: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    theme: {
      themeId: null,
      settings: null,
    },
  }),
  computed: {},
  apollo: {
    themeList: {
      query: GetAllThemes,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        return data.themes.map((theme) => ({
          value: theme.theme_id,
          text: this.$t(`settings.project.projectType.themeSetting.${theme.name}.name`),
        }));
      },
      skip() {
        return !this.groupId;
      },
    },
  },
  created() {
    this.theme.themeId = this.themeId;
    this.theme.settings = this.themeSettings;
  },
  methods: {
    changeThemeId(newThemeId) {
      this.theme.themeId = newThemeId;
      this.updateTheme();
    },
    updateThemeSettings(themeSettingValues) {
      this.theme.settings = themeSettingValues;
      this.updateTheme();
    },
    updateTheme() {
      this.$emit('update-theme-settings', this.theme);
    },
  },
};
</script>
