var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.project.projectType.name")) + " "
              )
            ]),
            _c("hr"),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.project.projectType.description")) +
                  " "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", { staticClass: "mb-0 mt-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.projectType.projectTypeLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-select", {
                attrs: { value: _vm.theme.themeId, options: _vm.themeList },
                on: { change: _vm.changeThemeId }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.theme.themeId
        ? _c("theme-settings-wrapper", {
            staticClass: "mb-4 pb-4",
            attrs: { theme: _vm.theme },
            on: { "update-theme-settings": _vm.updateThemeSettings }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }